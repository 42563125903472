<template>
  <div class="privacyService">
    <header>个税扣缴申报原则</header>
    <div class="vhtml" v-html="content.content" v-loading="loading"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      content: "",
    };
  },
  created() {
    this.getAgreement();
  },
  methods: {
    async getAgreement() {
      this.loading = true;
      let { code, data, message } = await this.$newsApi.getHomeGet_home({
        type: 10,
      });
      if (code == 200) {
        this.content = data;
      } else {
        this.$message.error(message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.privacyService {
  > header {
    background-color: white;
    height: 60px;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
  }
}
</style>
