<template>
  <div class="editInfo">
    <div class="user">
      <header>编辑资料</header>
      <div class="form">
        <div class="row">
          <span class="label">头像</span>
          <div class="img-user">
            <img
              :src="
                userInfo.avatar
                  ? userInfo.avatar
                  : require('@image/default.png')
              "
              class="avatar"
            />
            <div class="back">
              <span>更换头像</span>
            </div>
            <input
              @change="uploadImg"
              :disabled="isBtn2"
              title="更换头像"
              ref="avatarInput"
              type="file"
            />
          </div>
          <i v-show="isBtn2" class="el-icon-loading"></i>
        </div>
        <div class="row">
          <span class="label">姓名</span>
          <span v-show="!isInput" style="flex: 1" class="value">{{
            userInfo.nickname
          }}</span>
          <div v-show="isInput" class="input-name">
            <el-input
              autocomplete="off"
              placeholder="请输入姓名"
              maxlength="20"
              :autofocus="isInput"
              v-model="inputName"
              clearable
            >
            </el-input>
            <el-button :loading="isBtn" @click="postUpdateInfo(1, inputName)"
              >确定</el-button
            >
            <el-button @click="isInput = false" class="bai-btn">取消</el-button>
          </div>
          <i
            v-show="!isInput"
            @click="setNickname"
            title="修改姓名"
            class="iconfont bianji5"
          ></i>
        </div>
        <div class="row">
          <span class="label">身份认证</span>
          <div style="flex: 1"></div>
          <el-button
            v-if="userInfo.company && userInfo.company.company"
            @click="xin.goUrl('/authentication')"
            >去编辑</el-button
          >
          <el-button v-else @click="xin.goUrl('/authentication')"
            >去认证</el-button
          >
        </div>
        <div class="row">
          <span class="label">手机号码</span>
          <span class="value">当前已绑定:{{ userInfo.phone }}</span>
          <div style="flex: 1"></div>
          <span class="submit" @click="showEditMobile = true">更换手机</span>
        </div>
        <div class="row">
          <span class="label">微信账号</span>
          <span class="value" v-if="wxOpen_idCode">{{ wxOpen_idCode }}</span>
          <div style="flex: 1; text-align: right; margin-right: 10px">
            绑定第三方账号，可以直接登录网医平台
          </div>
          <span v-if="!wxOpen_id" class="submit" @click="postLoginBin_wxC"
            >去绑定</span
          >
          <span v-else @click="open_bind" v-show="userInfo.phone">解绑</span>
        </div>
        <div class="row">
          <span class="label">密码修改</span>
          <div style="flex: 1"></div>
          <span class="submit" @click="showEditPassword = true">修改</span>
        </div>
      </div>
    </div>
    <div class="account">
      <header>收款账户信息</header>
      <div class="form">
        <div class="row" v-for="(item, i) in popupType" :key="i">
          <span class="label">{{ item.name }}</span>
          <div style="flex: 1">{{ item.card }}</div>
          <i
            v-if="item.type == 2 && item.id"
            style="margin-right: 20px"
            title="编辑"
            class="iconfont bianji5"
            @click="editZFB(item)"
          ></i>
          <div
            v-if="item.id"
            class="default"
            @click="chonece(item, i)"
            :class="{ choni: item.is_default == 1 }"
          >
            <i
              class="iconfont"
              :class="
                item.is_default == 1
                  ? 'zhanghu-xuanzhong'
                  : 'zhanghu-weixuanzhong'
              "
            ></i>
            <span>设置为默认收款账户</span>
          </div>
          <div v-if="item.type == 3">
            <div style="width: 74px"></div>
            <!-- <el-button v-else @click="isEr=true;form.type = item.type">去添加</el-button> -->
            <div v-if="item.id" @click="gojieWechatC(item)" class="jiebang">
              解除绑定
            </div>
            <el-button v-else @click="goAddWx(item)">去添加</el-button>
          </div>

          <div v-else>
            <div v-if="item.id" @click="gojie(item)" class="jiebang">
              解除绑定
            </div>
            <el-button v-else @click="goAddZFB(item)">去添加</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 绑定微信公众号 -->
    <el-dialog
      width="400px"
      custom-class="back-box"
      top="10vh"
      show-close
      :visible.sync="isQrCode"
      :close-on-click-modal="false"
    >
      <span class="title">绑定微信公众号</span>
      <el-image v-if="qrCodeSrc" :src="qrCodeSrc" class="qr-img">
        <div @click="postLoginBin_wx" slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
          <div>二维码加载失败,点击重试</div>
        </div>
      </el-image>
      <div v-else class="qr-img" v-loading="!qrCodeSrc"></div>
      <div style="text-align: center; margin-top: 10px">扫码关注公众号</div>
      <i class="el-icon-close" @click="closeCode"></i>
    </el-dialog>

    <!-- 微信打款 -->
    <el-dialog
      width="400px"
      custom-class="back-box"
      top="10vh"
      show-close
      :visible.sync="isWXCode"
      :close-on-click-modal="false"
    >
      <span class="title">绑定微信号</span>
      <el-image v-if="wxCodeSrc" :src="wxCodeSrc" class="qr-img">
        <div @click="goAddWx" slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
          <div>二维码加载失败,点击重试</div>
        </div>
      </el-image>
      <div v-else class="qr-img" v-loading="!wxCodeSrc"></div>
      <div style="text-align: center; margin-top: 10px">扫码绑定微信号</div>
      <i class="el-icon-close" @click="closewxCode"></i>
    </el-dialog>

    <!-- 绑定微信 -->
    <el-dialog
      width="400px"
      custom-class="back-box"
      top="10vh"
      show-close
      :visible.sync="isWXCodeTwo"
      :close-on-click-modal="false"
    >
      <span class="title">绑定微信号</span>
      <el-image v-if="wxCodeSrcTwo" :src="wxCodeSrcTwo" class="qr-img">
        <div @click="goAddWx" slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
          <div>二维码加载失败,点击重试</div>
        </div>
      </el-image>
      <div v-else class="qr-img" v-loading="!wxCodeSrc"></div>
      <div style="text-align: center; margin-top: 10px">扫码绑定微信号</div>

      <i class="el-icon-close" @click="closewxCodeTwo"></i>
    </el-dialog>

    <el-dialog
      width="600px"
      custom-class="edit form-box"
      :visible.sync="showEditMobile"
      :before-close="handleClose"
    >
      <span class="title">手机号修改</span>
      <el-input
        autocomplete="off"
        v-model="editMobile.mobile"
        type="number"
        :maxlength="11"
        placeholder="请输入您的新手机号"
      ></el-input>
      <el-input
        autocomplete="off"
        v-model="editMobile.code"
        type="number"
        :maxlength="6"
        placeholder="请输入验证码"
        class="code"
      >
        <template slot="append">
          <div>
            <span v-if="isCode">{{ codeNum }}秒后重试</span>
            <span v-else @click="getCode(editMobile.mobile, 6)"
              >获取验证码</span
            >
          </div>
        </template>
      </el-input>
      <el-button :loading="isBtn3" @click="next">确定</el-button>
    </el-dialog>

    <el-dialog
      width="600px"
      custom-class="edit form-box"
      :visible.sync="showEditPassword"
      :before-close="handleClose"
    >
      <span class="title">修改密码</span>
      <el-input
        autocomplete="off"
        :value="userInfo.phone"
        readonly
        placeholder="您还未绑定手机号"
      ></el-input>
      <el-input
        v-model="editPassword.code"
        placeholder="请输入验证码"
        class="code"
        autocomplete="off"
      >
        <div slot="append">
          <span v-if="isCode">{{ codeNum }}秒后重试</span>
          <span v-else @click="getCode(userInfo.phone, 5)">获取验证码</span>
        </div>
      </el-input>
      <el-tooltip
        class="item"
        effect="light"
        content="密码8-24位,至少包括字母,大小写,数字,特殊字符（!@#$%^&*_-）"
        placement="top-start"
      >
        <el-input
          v-model="editPassword.old_pwd"
          show-password
          placeholder="请输入密码（8-24位）"
          style="margin-top: 20px"
          @blur="passwordVerify()"
          autocomplete="off"
        ></el-input>
      </el-tooltip>
      <el-input
        autocomplete="off"
        v-model="editPassword.new_pwd"
        show-password
        placeholder="请再次输入密码"
        style="margin-top: 20px"
      ></el-input>
      <el-button :loading="isBtn3" @click="config">确定</el-button>
    </el-dialog>

    <el-dialog
      width="600px"
      custom-class="login"
      :visible.sync="showLogin"
      :before-close="closeCodeXin"
    >
      <div class="section">
        <div class="QR-Login">
          <wxlogin
            :appid="'wx6b2893abfdaf4bd7'"
            :scope="'snsapi_login'"
            :redirect_uri="encodeURI('http://www.idr.cn//#/setting')"
          ></wxlogin>
          <!-- :redirect_uri="encodeURI('http://www.idr.cn//#/index')" -->
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="600px"
      custom-class="back-box"
      top="10vh"
      :visible.sync="isBank"
      :before-close="handleClose"
    >
      <div v-if="form.type == 1">
        <span class="title">添加银行卡</span>
        <div class="chin-zi">
          <div class="left-title">银行名称</div>
          <el-select
            v-model="form.bank_id"
            :disabled="isBin"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in bankList"
              :key="index"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
            <!-- <span style="float: right">{{ item.text }}</span> -->
            <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
          </el-select>
        </div>
        <div class="chin-zi">
          <div class="left-title">开户银行</div>
          <el-input
            autocomplete="off"
            style="flex: 1"
            v-model="form.bank_name"
            :readonly="isBin"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="chin-zi">
          <div class="left-title">持卡人姓名</div>
          <el-input
            autocomplete="off"
            style="flex: 1"
            v-model="form.username"
            :readonly="isBin"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="chin-zi">
          <div class="left-title">银行卡卡号</div>
          <el-input
            autocomplete="off"
            style="flex: 1"
            type="text"
            v-model="form.card"
            :readonly="isBin"
            placeholder="请输入您的卡号"
          ></el-input>
        </div>
        <div v-if="!isBin" class="chin-zi">
          <div class="left-title">手机号</div>
          <el-input
            autocomplete="off"
            style="flex: 1"
            type="text"
            v-model="form.photo"
            :readonly="isBin"
            placeholder="请输入您的手机号"
          ></el-input>
        </div>
      </div>

      <!-- 支付宝 -->
      <div v-else>
        <span class="title">添加支付宝账号</span>
        <div class="chin-zi">
          <div class="left-title">支付宝账号</div>
          <el-input
            autocomplete="off"
            style="flex: 1"
            v-model="form.card"
            :readonly="isBin"
            placeholder="请输入支付宝账号"
          ></el-input>
        </div>
        <div class="chin-zi">
          <div class="left-title">支付宝姓名</div>
          <el-input
            autocomplete="off"
            style="flex: 1"
            v-model="form.username"
            :readonly="isBin"
            placeholder="请输入真实姓名"
          ></el-input>
        </div>
        <div v-if="!isBin" class="chin-zi">
          <div class="left-title">手机号</div>
          <el-input
            autocomplete="off"
            style="flex: 1"
            type="text"
            v-model="form.photo"
            :readonly="isBin"
            placeholder="请输入您的支付宝手机号"
          ></el-input>
        </div>
      </div>

      <div v-if="!isBin" class="chin-zi">
        <div class="left-title">验证码</div>
        <div class="right-box">
          <el-input
            autocomplete="off"
            style="flex: 1"
            v-model="form.code"
            :readonly="isBin"
            placeholder="请填写"
          ></el-input>
          |
          <span class="code-num">
            <span v-if="isCode">{{ codeNum }}秒后重试</span>
            <span v-else @click="getCode(form.photo, 1)">获取验证码</span>
          </span>
        </div>
      </div>
      <div class="chin-zi">
        <div class="left-title2">设置为默认收款账户</div>
        <el-switch
          :disabled="isBin"
          v-model="form.is_default"
          active-color="#03DBC6"
        >
        </el-switch>
      </div>
      <div class="maaotu">
        <div class="checkbox-el">
          <el-checkbox class="readAndAgree" v-model="checked"> </el-checkbox>
          阅读并同意
          <span
            @click.stop="
              isBank = false;
              xin.goUrl('/setting?index=4');
            "
            >《个税代缴代付协议》</span
          >
        </div>
        <el-button v-if="isBin" :loading="isBtn3" @click="postUpdateDelBank"
          >解除绑定</el-button
        >
        <el-button v-else :loading="isBtn3" @click="addBankCard"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      width="600px"
      custom-class="back-box"
      top="10vh"
      :visible.sync="isEr"
      :before-close="handleClose"
    >
      <span class="title">添加微信账号</span>
      <div class="chin-zi">
        <div class="left-title">微信用户名</div>
        <el-input
          autocomplete="off"
          style="flex: 1"
          v-model="form.bank_name"
          :readonly="isBin"
          placeholder="请输入真实姓名"
        ></el-input>
      </div>
      <div class="erwm-box">
        <img :src="$http.baseURL + '/static/images/web/image.png'" alt="" />
        <div class="title-erw">通过微信扫描识别二维码</div>
      </div>
      <div class="maaotu">
        <div class="checkbox-el">
          <el-checkbox class="readAndAgree" v-model="checked"> </el-checkbox>
          阅读并同意
          <span
            @click.stop="
              isEr = false;
              xin.goUrl('/setting?index=4');
            "
            >《个税代缴代付协议》</span
          >
        </div>
        <el-button v-if="isBin" :loading="isBtn3" @click="postUpdateDelBank"
          >解除绑定</el-button
        >
        <el-button v-else :loading="isBtn3" @click="addBankCard"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      width="600px"
      custom-class="back-box"
      top="10vh"
      :visible.sync="isErTwo"
      :before-close="handleCloseEr"
    >
      <span class="title" style="margin: 0 auto"
        >您是否解除微信收款账号绑定</span
      >
      <div class="maaotu">
        <el-button @click="gojieWechat">解除绑定</el-button>
        <el-button :loading="isBtn3" @click="gojieWechatTwo">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { mapState, mapActions, mapMutations } from "vuex";
// import wxlogin from "vue-wxlogin";
import { mapState, mapActions, mapMutations } from "vuex";
// import { mapState, mapActions } from "vuex";
import store from "@/store";
import wxlogin from "vue-wxlogin";
export default {
  components: {
    wxlogin,
  },
  data() {
    return {
      isErTwo: false,
      wxCodeSrcTwo: "",
      isWXCodeTwo: false,
      idCh: "",
      wxOpen_id: "",
      wxOpen_idCode: "",
      showLogin: false,
      isloading: false,
      qrCodeSrc: "", //二维码地址
      wxCodeSrc: "", //微信二维码图片
      isQrCode: false, //二维码是否显示
      isWXCode: false, //微信打款

      codeKey: "", //二维码key
      cunrli: -1, //二维码定时器
      isInput: false, //修改昵称输入框显示
      inputName: "", //临时昵称
      isBtn: false, //按钮加载
      isBtn2: false, //头像加载
      isBtn3: false, //弹框按钮加载
      isCode: false, //短信倒计时
      codeNum: 60,
      editMobile: {
        //修改手机号
        mobile: null,
        code: null,
      },
      editPassword: {
        code: null,
        old_pwd: null,
        new_pwd: null,
      },
      showEditMobile: false,
      showEditPassword: false,
      isBank: false,
      checked: false, //协议
      isBin: false, //只读
      isEr: false, //微信绑定
      bankList: [],
      form: {
        bank_id: "", //银行id
        bank_name: "", //支行名称
        username: "", //名称
        card: "", //卡号
        type: 1, //1银行卡 2支付宝
        photo: "", //手机号
        code: "", //验证码
        is_default: false, //是否默认
      },
      popupType: [
        // {
        //   icon: "iconyinhangka",
        //   name: "银行收款",
        //   is_show: 1,
        //   type: 1,
        // },
        {
          icon: "iconweixin",
          name: "微信收款",
          is_show: 1,
          type: 3,
        },
        {
          icon: "iconzhifubao",
          name: "支付宝收款",
          is_show: 1,
          type: 2,
        },
      ],
      timeId: -3,
    };
  },
  created() {
    this.getBankList();
    this.getListBank();
    this.userInfoFun();
  },
  methods: {
    ...mapActions("userManagement", ["getUserInfo"]),
    ...mapMutations("userManagement", ["setUser"]),
    async userInfoFun() {
      let res = await this.$newsApi.userInfo();
      if (res.code == 200) {
        this.wxOpen_id = res.data.open_id;
        this.wxOpen_idCode = res.data.wx_name;
      } else {
        this.$message.error(res.message);
      }
    },
    gojieWechatTwo() {
      this.isErTwo = false;
    },
    async open_bind() {
      let res = await this.$newsApi.open_bind({
        token: localStorage.getItem("token"),
      });
      if (res.code == 200) {
        this.userInfoFun();
        // this.isWXCodeTwo=true
        // this.wxCodeSrcTwo=res.data
      } else {
        this.$message.error(res.message);
      }
    },
    emptyThe() {
      //清空短信倒计时
      this.isCode = false;
      this.codeNum = 60;
      clearTimeout(this.inter);
    },
    closeCodeXin(dom) {
      this.qrCodeSrc = "";
      this.loginType = "account";
      clearInterval(this.cunrli);
      if (dom) dom();
      this.showLogin = false;
    },
    async postLoginBin_wxC() {
      let res = await this.$newsApi.createQRCode({
        token: localStorage.getItem("token"),
      });
      if (res.code == 200) {
        this.isWXCodeTwo = true;
        this.wxCodeSrcTwo = res.data;
      } else {
        this.$message.error(res.message);
      }
      // this.qrCodeSrc = "";
      // this.showLogin = true;
      // this.loginType = 'QR';
      // this.postLoginBin_wx()

      // return;
      // let { code, data } = await this.$userApi.postGenerateTwoQrcode({ login: 1 });
      // if (code == 200) {
      //     this.qrCodeSrc = data.url;
      //     this.codeKey = data.key;
      //     this.qrPolling();
      // }
    },

    // 密码校验
    passwordVerify() {
      let reg =
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*_-])[0-9a-zA-Z!@#$%^&*_-]{8,24}$/;
      if (!reg.test(this.editPassword.old_pwd)) {
        this.$message.error("密码格式有误");
        this.editPassword.old_pwd = "";
      }
    },
    async goAddWx() {
      // this.qrCodeSrc = "";

      let res = await this.$userApi.getSmallCode({ user_id: this.userInfo.id });

      if (res.code == 200) {
        this.isWXCode = true;
        this.wxCodeSrc = res.message;
        this.timeId = setInterval(() => {
          this.getListBank();
        }, 3000);
      } else {
        this.$message.error(res.message);
      }
    },
    goAddZFB(item) {
      this.isBank = true;
      this.form.type = item.type;
    },

    // 修改支付宝
    editZFB(item) {
      //打开解绑
      this.isBank = true;
      let form = Object.assign({}, item);
      form.photo = this.userInfo.phone;
      form.is_default = form.is_default == 1;
      this.form = form;
    },
    closeCode() {
      //关闭二维码
      this.isQrCode = false;
      this.qrCodeSrc = "";
      clearInterval(this.cunrli);
    },
    closewxCode() {
      this.isWXCode = false;
      this.wxCodeSrc = "";
      clearInterval(this.timeId);
    },
    closewxCodeTwo() {
      this.isWXCodeTwo = false;
      this.wxCodeSrcTwo = "";
      this.userInfoFun();
    },

    async postLoginBin_wx() {
      //绑定公众号
      this.qrCodeSrc = "";
      this.isQrCode = true;
      let { code, data, message } = await this.$userApi.postLoginBin_wx();
      if (code == 200) {
        let { code: co1, data: res } =
          await this.$userApi.postGenerateTwoQrcode({ key: data.key });
        if (co1 == 200) {
          this.qrCodeSrc = res.url;
          this.codeKey = res.key;
          this.qrPolling();
        } else {
          this.$message.error(res.message);
        }
      } else {
        this.$message.error(message);
      }
    },
    qrPolling() {
      //二维码轮询
      this.cunrli = setInterval(async () => {
        let { code, message } = await this.$userApi.getLoginQuery({
          key: this.codeKey,
        });
        if (code == 200) {
          this.xin.message("绑定成功!");
          clearInterval(this.cunrli);
          this.isQrCode = false;
          this.getUserInfo();
        } else if (code != 400) {
          clearInterval(this.cunrli);
        } else {
          this.$message.error(message);
        }
      }, 3000);
    },
    gojieWechatC() {
      this.isErTwo = true;
    },
    async gojieWechat() {
      let res = await this.$userApi.del_bank({
        id: this.idCh,
      });
      if (res.code == 200) {
        this.xin.message("解绑成功!");
        await this.getListBank();
        this.isErTwo = false;
      } else {
        this.$message.error(res.message);
      }
    },
    gojie(item) {
      //打开解绑
      this.isBank = true;
      let form = Object.assign({}, item);
      form.photo = this.userInfo.phone;
      form.is_default = form.is_default == 1;
      this.form = form;
      this.isBin = true;
    },
    async postUpdateDelBank() {
      //解绑
      if (!this.checked) {
        this.xin.message("未勾选《个税代缴代付协议》", "err");
        return;
      }
      this.isBtn3 = true;
      let { code, message } = await this.$userApi.postUpdateDelBank({
        id: this.form.id,
      });
      if (code == 200) {
        this.xin.message("解绑成功!");
        this.isBank = false;
        this.handleClose();
        await this.getListBank();
        this.editMobile = { mobile: "", code: "" };
        this.editPassword = {
          code: null,
          old_pwd: null,
          new_pwd: null,
        };
        this.isBin = false;
        this.checked = false;
        this.emptyThe();
        setTimeout(() => {
          Object.assign(this.$data.form, this.$options.data().form);
        }, 300);
      } else {
        this.$message.error(message);
      }
      this.isBtn3 = false;
    },
    async chonece(item, i) {
      //设置默认收款账号
      if (!item.id) {
        this.xin.message("您还未设置收款账号");
        return;
      } else if (item.is_default == 1) return;
      // uni.showLoading();
      let { code, message } = await this.$userApi.postSetDefaultBank({
        id: item.id,
      });
      // uni.hideLoading();
      if (code == 200) {
        this.popupType.forEach((v, index) => {
          if (i == index) {
            v.is_default = 1;
          } else v.is_default = 0;
        });
        this.$forceUpdate();
        this.xin.message("设置成功!");
      } else {
        this.$message.error(message);
      }
    },
    async getListBank() {
      //获取用户银行卡
      Object.assign(this.$data.popupType, this.$options.data().popupType);
      let res = await this.$userApi.getListBank();
      if (res.code == 200) {
        res.data.forEach((v, i) => {
          if (v.type == 1) {
            // Object.assign(this.popupType[0], v);
          } else if (v.type == 2) {
            Object.assign(this.popupType[1], v);
          } else if (v.type == 3) {
            Object.assign(this.popupType[0], v);
            this.idCh = res.data[0].id;
            // Object.assign(this.popupType[1], v);
          }
        });
        this.$forceUpdate();
        // this.popupType = data;
      } else {
        this.$message.error(res.message);
      }
    },
    async getBankList() {
      //获取银行卡
      let { data, code, message } = await this.$userApi.getBankList();
      if (code == 200) {
        let indexList = [];
        for (let i in data) {
          indexList.push(...data[i]);
          // for (let k in data[i]) {
          //   let os = indexList.filter((v) => v.id == data[i][k].id);
          //   if (os.lenght == 0) {
          //     indexList.push(data[i][k]);
          //   }
          // }
        }
        this.bankList = indexList;
      } else {
        this.$message.error(message);
      }
    },
    alipayVerify() {
      //支付宝验证
      let { bank_id, bank_name, username, card, photo, code } = this.form;
      if (!card) {
        this.xin.message("请输入支付宝账号", "err");
      } else if (!username) {
        this.xin.message("请输入姓名真实姓名", "err");
      } else if (!photo) {
        this.xin.message("请输入您的支付宝手机号", "err");
      } else if (!code) {
        this.xin.message("请输入验证码", "err");
      } else if (!this.checked) {
        this.xin.message("未勾选《个税代缴代付协议》", "err");
      } else {
        return true;
      }
      return false;
    },
    bankVerify() {
      //银行卡验证
      let { bank_id, bank_name, username, card, photo, code } = this.form;
      if (!bank_id) {
        this.xin.message("请选择银行", "err");
      } else if (!bank_name) {
        this.xin.message("请输入开户银行", "err");
      } else if (!username) {
        this.xin.message("请输入持卡人姓名", "err");
      } else if (!card) {
        this.xin.message("请输入银行卡卡号", "err");
      } else if (!photo) {
        this.xin.message("您还未绑定手机号", "err");
      } else if (!code) {
        this.xin.message("请输入验证码", "err");
      } else if (!this.checked) {
        this.xin.message("未勾选《个税代缴代付协议》", "err");
      } else {
        return true;
      }
      return false;
    },
    async addBankCard() {
      //添加银行卡
      let form = Object.assign({}, this.form);
      if (form.type == 1) {
        if (!this.bankVerify()) return;
      } else {
        if (!this.alipayVerify()) return;
      }
      form.is_default = form.is_default ? 1 : 0;
      this.isBtn3 = true;
      let res = await this.$userApi.postAddBank(form);
      if (res.code == 200) {
        this.isBank = false;
        this.xin.message("添加成功!");
        this.emptyThe();
        this.getListBank();
        await this.getListBank();
      } else {
        this.$message.error(res.message);
      }
      this.isBtn3 = false;
    },
    async config() {
      //修改密码
      let { old_pwd, new_pwd, code } = this.editPassword;
      if (!code) {
        this.xin.message("请输入验证码", "err");
        return;
      } else if (!old_pwd) {
        this.xin.message("请输入新密码", "err");
        return;
      } else if (!new_pwd) {
        this.xin.message("请再次输入密码", "err");
        return;
      } else if (old_pwd != new_pwd) {
        this.xin.message("两次密码不一致", "err");
        return;
      }
      this.isBtn3 = true;
      // let { code: code2 } = await this.$userApi.postQueryMobile({
      let res = await this.$userApi.postQueryMobile({
        phone: this.userInfo.phone,
        code,
      });
      if (res.code == 200) {
        let resTwo = await this.$userApi.postQueryPassword({
          old_pwd,
          new_pwd,
        });
        if (resTwo.code == 200) {
          this.xin.message("修改成功!");
          this.handleClose();
          this.showEditPassword = false;
        } else {
          this.xin.message(resTwo.message, "err");
        }
      } else {
        this.$message.error(res.message);
      }

      this.isBtn3 = false;
    },
    handleCloseEr() {
      this.isErTwo = false;
    },
    handleClose(done) {
      if (this.isBtn3) return;
      if (done) done();
      this.editMobile = { mobile: "", code: "" };
      this.editPassword = {
        code: null,
        old_pwd: null,
        new_pwd: null,
      };
      this.isBin = false;
      this.checked = false;
      setTimeout(() => {
        Object.assign(this.$data.form, this.$options.data().form);
      }, 300);
    },
    async next() {
      //修改手机号
      let { mobile, code } = this.editMobile;
      if (!mobile) {
        this.xin.message("请输入手机号", "err");
        return;
      } else if (!code) {
        this.xin.message("请输入验证码", "err");
        return;
      }
      this.isBtn3 = true;
      let res = await this.$userApi.postUpdateMobile({
        phone: mobile,
        code,
      });
      if (res.code == 200) {
        await this.getUserInfo();
        this.xin.message(res.data);
        this.showEditMobile = false;
        this.isBtn3 = false;
        this.editMobile = { mobile: "", code: "" };
        this.editPassword = {
          code: null,
          old_pwd: null,
          new_pwd: null,
        };
        this.isBin = false;
        this.checked = false;
        setTimeout(() => {
          Object.assign(this.$data.form, this.$options.data().form);
        }, 300);
      } else {
        this.$message.error(res.message);
      }
      this.isBtn3 = false;
    },
    async getCode(mobile, type) {
      if (!mobile) {
        this.xin.message("手机号不能为空!", "err");
        return;
      }
      this.isCode = true;
      let res = await this.$loginApi.massCode({ phone: mobile, type });
      this.isCode = false;
      if (res.code == 200) {
        this.$message({ message: "短信已发送,注意查收", type: "success" });
        this.codeNum = 60;
        this.isCode = true;
        this.inter = setInterval(() => {
          this.codeNum -= 1;
          if (this.codeNum <= 0) {
            this.isCode = false;
            clearTimeout(this.inter);
          }
        }, 1000);
      } else {
        this.$message({ message: res.message, type: "error" });
      }
    },
    async uploadImg() {
      //图片上传
      let files = this.$refs.avatarInput.files;
      if (!files.length) {
        return;
      }
      let formData = new FormData();
      formData.append("files", files[files.length - 1]);
      // this.xin.isLoadding(true)
      this.isBtn2 = true;
      let { code, data, message } = await this.$userApi.postFilesUpload(
        formData
      );
      if (code == 200) {
        this.postUpdateInfo(2, undefined, data);
      } else {
        this.$message.error(message);
      }
      // this.xin.isLoadding()
    },
    setNickname() {
      //修改昵称
      this.inputName = this.userInfo.nickname;
      this.isInput = true;
    },
    async postUpdateInfo(type, nickname = "", avatar) {
      nickname = nickname.replace(/\s*/g, "");
      this.nickname = nickname;
      if (!nickname && type == 1) {
        this.xin.message("请输入姓名", "err");
        return;
      } else if (nickname.length > 10 && type == 1) {
        this.xin.message("姓名长度不能大于10个字符", "err");
        return;
      }
      if (!nickname) nickname = undefined;
      this.isBtn = true;
      let { code, message } = await this.$userApi.postUpdateInfo({
        nickname,
        avatar,
      });
      if (code == 200) {
        await this.getUserInfo();
        if (type == 1) {
          this.xin.message("修改成功!");
        } else {
          this.xin.message("添加成功!");
          this.userInfo.avatar = avatar;
          this.setUser({ avatar: avatar });
        }
        this.nikename = "";
        this.isInput = false;
      } else {
        this.$message.error(message);
      }
      this.isBtn = false;
      this.isBtn2 = false;
    },
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

/deep/.form-box .el-dialog__body .el-input {
  height: 44px;
  background: #f2f2f2;
  border: none;
  border-radius: 44px;
  // overflow: hidden;
  .el-input__inner {
    height: 100%;
    border: none;
    background: none;
    border: 0;
    border-radius: 0;
  }
  .el-input__icon {
    line-height: 44px;
  }
}
/deep/ .el-popper[x-placement^="bottom"] .popper__arrow {
  right: 0;
  background: red;
}
/deep/.back-box {
  position: relative;
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
    color: #333;
  }
  .qr-img {
    margin: auto;
    width: 300px;
    height: 300px;
    background: #c5c7ca;
    border-radius: 10px;
    .image-slot {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
      div {
        margin-top: 10px;
        font-size: 18px;
      }
    }
  }
  .erwm-box {
    text-align: center;
    img {
      width: 183px;
      height: 183px;
      margin: 20px auto;
    }
    .title-erw {
      font-size: 12px;
      color: #352b5c;
    }
  }
  .readAndAgree {
    margin-right: 10px;
  }
  .chin-zi {
    display: flex;
    width: 100%;
    height: 62px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;

    .left-title {
      color: #333;
      font-weight: 400;
    }
    .el-input__inner {
      border: none;
      text-align: right;
    }
    .el-input.is-disabled .el-input__icon {
      cursor: default;
    }
    .el-input.is-disabled .el-input__inner {
      color: #606266;
      background-color: #0000;
      cursor: default;
    }
    .right-box {
      flex: 1;
      display: flex;
      align-items: center;
      color: #8f8f8f;
      .code-num {
        cursor: pointer;
        margin-left: 10px;
        color: $--themeColor;
      }
    }
  }
  .el-dialog__body {
    box-sizing: border-box;
    padding: 50px 50px;
    .title {
      font-size: 24px;
      color: #333333;
      margin-bottom: 30px;
    }
    .code {
      margin-top: 20px;
      .el-input-group__append {
        background: none;
        border: none;
        color: $--themeColor;
        cursor: pointer;
      }
    }
    .maaotu {
      margin-top: 50px;
      text-align: center;
      .el-button {
        margin-top: 10px;
        border: none;
        height: 44px;
        font-size: 18px;
        color: white;
        background-color: $--themeColor;
        border-radius: 44px;
        width: 360px;
      }
    }
  }
}
.editInfo {
  > div {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    > header {
      background-color: white;
      height: 60px;
      box-sizing: border-box;
      padding: 0 30px;
      font-size: 24px;
      color: #333333;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
    }
    > .form {
      display: flex;
      flex-direction: column;
      background-color: white;
      box-sizing: border-box;
      padding: 0 30px;
      > .row {
        align-items: center;
        box-sizing: border-box;
        padding: 30px 0;
        border-bottom: 1px solid #f2f2f2;
        .jiebang {
          font-size: 18px;
          cursor: pointer;
          color: #352b5c;
        }
        .el-icon-loading {
          font-size: 30px;
          margin-left: 10px;
        }
        .img-user {
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          &:hover .back {
            top: 40px;
          }
          .back {
            position: absolute;
            height: 40px;
            width: 100%;
            top: 80px;
            transition: all 0.2s linear;
            font-size: 12px;
            background: #0003;
            color: #fff;
            text-align: center;
            line-height: 30px;
          }
          input {
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
        .bianji5 {
          padding: 5px;
          cursor: pointer;
        }
        .input-name {
          display: flex;
          .el-button {
            height: 40px;
            margin-left: 10px;
          }
        }
        &:last-child {
          border: none;
        }
        > .label {
          font-size: 18px;
          color: #333333;
          width: 140px;
        }
        > .value {
          font-size: 18px;
          color: #8f8f8f;
        }
        > .submit {
          font-size: 18px;
          color: #352b5c;
          cursor: pointer;
        }
        .choni {
          color: #ff8307;
        }
        color: #8f8f8f;
        > .default {
          cursor: pointer;
          font-size: 18px;
          display: flex;
          align-items: center;
          margin-right: 60px;
          .iconfont {
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .bai-btn {
          color: #8f8f8f;
          background: #0000;
          border: 1px solid #8f8f8f;
        }
      }
      .el-button {
        border: none;
        height: 36px;
        font-size: 18px;
        color: white;
        background-color: $--themeColor;
        border-radius: 5px;
        padding: 0 25px;
      }
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        border-radius: 80px;
        text-align: center;
      }
      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        display: block;
        object-fit: cover;
      }
    }
  }
  ::v-deep {
    .edit {
      border-radius: 0;
      .el-dialog__body {
        box-sizing: border-box;
        padding: 50px 120px;
        > .title {
          font-size: 18px;
          color: #333333;
          margin-bottom: 30px;
        }
        > .code {
          margin-top: 20px;
          .el-input-group__append {
            background: none;
            border: none;
            color: $--themeColor;
            cursor: pointer;
          }
        }
        > .el-button {
          margin-top: 50px;
          border: none;
          height: 44px;
          font-size: 18px;
          color: white;
          background-color: $--themeColor;
          border-radius: 44px;
          width: 360px;
        }
      }
    }
  }
}
.QR-Login {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  padding: 10px;

  .qr-img {
    background: #c5c7ca;
    border-radius: 10px;
    width: 200px;
    height: 200px;

    .image-slot {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      font-size: 30px;
      cursor: pointer;

      div {
        margin-top: 10px;
        font-size: 18px;
      }
    }
  }

  > span {
    margin-top: 10px;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
  }
}
</style>
