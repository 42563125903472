<template>
  <div class="userAgreement">
    <header>用户协议</header>
    <div class="vhtml" v-html="content" v-loading="loading"></div>
    <!-- <iframe
      name="aa"
      ref="ifram"
      scrolling="no"
      id="external-frame"
      marginwidth="0"
      marginheight="0"
      width="100%"
      :src="$http.baseURL + '/static/dkt.html'"
      frameborder="0"
    >
    </iframe> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      content: "",
      height: 0,
    };
  },
  created() {
    this.getAgreement();
  },
  methods: {
    load() {
      this.height = this.$refs.ifrm.document.body.srcollHeight;
    },
    setIframeHeight(iframe) {
      if (iframe) {
        var iframeWin =
          iframe.contentWindow || iframe.contentDocument.parentWindow;
        if (iframeWin.document.body) {
          iframe.height =
            iframeWin.document.documentElement.scrollHeight ||
            iframeWin.document.body.scrollHeight;
        }
      }
    },

    // window.onload = function () {
    // setIframeHeight(document.getElementById('external-frame'));
    // },
    async getAgreement() {
      this.loading = true;
      let { code, data, message } = await this.$loginApi.getAgreement();
      this.loading = false;
      if (code == 200) {
        this.content = data.user_agreement;
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userAgreement {
  > header {
    background-color: white;
    height: 60px;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
  }
}
iframe {
  background: #fff;
  padding: 0 30px;
  box-sizing: border-box;
  min-height: 5000px;
}
</style>
