<template>
  <div class="privacyService">
    <header>隐私服务</header>
    <div class="vhtml" v-html="content" v-loading="loading"></div>
    <!--   <iframe
      name="aa"
      scrolling="no"
      id="external-frame"
      marginwidth="0"
      marginheight="0"
      width="100%"
      :src="$http.baseURL + '/static/yszc.html'"
      frameborder="0"
    >
    </iframe> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      content: "",
    };
  },
  created() {
    this.getAgreement();
  },
  methods: {
    async getAgreement() {
      this.loading = true;
      let { code, data, message } = await this.$loginApi.getAgreementMax();
      this.loading = false;
      if (code == 200) {
        this.content = data.privacy_agreement;
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.privacyService {
  > header {
    background-color: white;
    height: 60px;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
  }
}

iframe {
  background: #fff;
  padding: 0 30px;
  box-sizing: border-box;
  min-height: 5000px;
}
</style>
<style>
</style>
